html,
body {
  margin: 0;
  padding: 0;
  overflow-x: auto;
}

.site-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 2250px;
  margin: auto;
}

.lock-scroll {
  overflow-y: hidden;
}

.wrapper {
  max-width: 2250px;
  margin: 0 auto;

  /* min-width: 275px; */
}

:root {
  --white-dark: hsl(0, 0%, 95%);
  --blue: rgba(0, 119, 181, 0.8);
  --burgundy: rgba(140, 28, 86, 1);
  --pink: rgba(255, 158, 166, 1);
  --grey: rgba(153, 153, 153, 1);
  /* --dark-burgundy: rgba(104, 12, 52, 1); */
  --dark-burgundy: rgba(96, 9, 52, 1);
  --salmon: rgba(255, 122, 127, 1);

  --web-border-radius: 5px;
  --header-font-size-d: 50px;
  --small-text-size-d: 16px;
  --header-font-size-m: 40px;
  --small-text-size-m: 14px;
  --stats-mobile-font: 70px;
  --stats-desktop-font: 90px;
}

img {
  max-width: 100%;
  height: auto;
}

/* Variants of the SourceSansPro font to be used in font-family style */
@font-face {
  font-family: "Source-L";
  src: url("./fonts/SourceSansPro-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Source-R";
  src: url("./fonts/SourceSansPro-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Source-B";
  src: url("./fonts/SourceSansPro-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Lobster";
  src: url("./fonts/Lobster-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Glacial-Indifference-Regular";
  src: url("./fonts/GlacialIndifference-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Glacial-Indifference-Bold";
  src: url("./fonts/GlacialIndifference-Bold.otf") format("opentype");
}

a {
  text-decoration: none;
}

textarea {
  resize: none;
}

.heading-primary {
  color: black;
  font-family: "Glacial-Indifference-Bold";
  font-size: 4rem;
  text-transform: uppercase;
  margin: 20px 0 20px 0;
  /* max-width: 50%; */
}

.heading-primary-white {
  color: white;
  padding-top: 2rem;
}

.heading-secondary {
  color: black;
  font-family: "Glacial-Indifference-Bold";
  font-size: 56px;
  text-transform: uppercase;
}

.heading-profile {
  color: black;
  font-family: "Glacial-Indifference-Bold";
  font-size: 2rem;
  margin-top: 0rem;
  margin-bottom: 0.5rem;
  text-align: center;
}

.heading-section {
  color: black;
  font-family: "Glacial-Indifference-Regular";
  font-size: 2rem;
}

.text-primary {
  color: black;
  font-family: "Glacial-Indifference-Regular";
  font-size: 1.8rem;
  max-width: 60%;
  padding-top: 2rem;
}

.section-padding {
  padding: 2rem 4rem;
}

.heading-secondary {
  font-family: "Glacial-Indifference-Bold";
  font-size: var(--header-font-size-d);
  font-weight: bolder;
}

.trademark-span {
  white-space: nowrap;
  /* Prevents text from wrapping to the next line */
  overflow: hidden;
  /* Hides any overflowed text */
  text-overflow: ellipsis;
  /* Displays an ellipsis (...) for overflowed text */
  font-weight: bold;
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeLeft {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeRight {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@media only screen and (max-width: 1300px) {
}

@media only screen and (max-width: 1300px) {
  .heading-primary {
    font-size: 3.25rem;
  }
}

@media only screen and (max-width: 900px) {
  .section-padding {
    padding: 2rem 2rem;
  }
}

@media only screen and (max-width: 700px) {
  .heading-primary {
    font-size: 3.25rem;
  }
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #c0c7ca;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #9cadb1;
}
