.projects-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
  margin: 0 0 0 0;
  justify-content: space-between;
}

.project-card {
  position: relative;
  margin: 0 20px 50px;
}

.project-card:hover {
  transform: scale(1.015);
  cursor: pointer;
}

.project-img-container img {
  width: 40rem;
  object-fit: cover;
  transition: transform 0.3s ease;
  border-radius: var(--web-border-radius);
  filter: brightness(0.9);
}

.project-name-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  text-align: left;
  font-family: "Glacial-Indifference-Bold";
  font-size: 1.5rem;
  width: 80%;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
}

.project-popup-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  height: 100%;
  overflow-y: auto;
  width: 60rem;
}

.project-popup-flex {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 75vh;
  width: 75vw;
  max-width: 1200px;
  max-height: 700px;
  min-width: 250px;
  border-radius: var(--web-border-radius);
  /* Add this line */
}

.project-popup-heading {
  font-size: 2.2rem;
}
.project-flag {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2;
  width: 60px;
}

.project-banner {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: var(--burgundy);
  color: white;
  font-size: 1rem;
  font-family: "Glacial-Indifference-Bold";
  letter-spacing: 1px;
  padding: 7px 20px;
  border-radius: 5px;
  text-transform: uppercase;
  z-index: 2;
}

.project-banner-left {
  right: 0;
  left: 10px;
  width: max-content;
}

.project-popup-img-container {
  background-color: transparent;
}

.project-popup-img-container img {
  object-fit: cover;
  vertical-align: middle;
  width: 25rem;
  height: 100%;
  max-width: none;
}
.project-popup-map-container {
  width: 100%;
  height: 100%; /* Ensure this is not 0 */
  position: relative;
}

.description-text-project {
  padding: 0.5rem 0;
}

@media only screen and (max-width: 1300px) {
  .project-popup-text-container {
    width: 40rem;
  }

  .project-img-container img {
    /* width: auto; */
    height: auto;
  }

  .projects-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: 100%;
  }
  .project-name-container {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 900px) {
  .project-popup-text-container {
    width: 100%;
  }
  .projects-wrapper {
    max-width: 100%;
  }

  .project-popup-img-container {
    display: none;
  }
  .project-popup-heading {
    font-size: 2rem;
    margin: 0;
    padding: 0;
  }

  .project-banner {
    text-align: center;
    max-width: 125px;
  }
  .project-banner-left {
    display: none;
  }
}
@media only screen and (max-width: 700px) {
  .projects-wrapper {
    grid-template-columns: auto;
    max-width: 100%;
    justify-content: space-evenly;
  }
  .project-popup-flex {
    flex-direction: column;
  }
}
