.quality-page {
    z-index: 2;
}


.quality-heading-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}


.quality-heading {
    font-family: 'Glacial-Indifference-Bold';
    font-size: var(--header-font-size-d);
    font-weight: bolder;
    text-transform: uppercase;
}


.quality-text-1 {
    color: var(--dark-burgundy);
    font-size: 28px;
    font-family: 'Glacial-Indifference-Regular';
    padding-top: 0.75rem;
}

.quality-text-2 {
    color: var(--salmon);
    font-size: 24px;
    font-family: 'Glacial-Indifference-Regular';
    max-width: 90%;
    padding-top: 0.75rem;
}

.quality-text-3 {
    padding-top: 1.25rem;
    color: var(--burgundy);
    font-size: 20px;
    font-family: 'Glacial-Indifference-Regular';
    max-width: 90%;
}

.quality-modules {
    padding-top: 4rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

}



.quality-module {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem 1rem;
    width: 40%;
    opacity: 0;
}

.quality-module.fadeLeft {
    animation: fadeLeft 0.75s;
    animation-duration: 0.75s;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
    animation-play-state: running;
}

.quality-module.fadeRight {
    animation: fadeRight 0.75s;
    animation-duration: 0.75s;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
    animation-play-state: running;
}

.quality-module.animation-done {
    animation: none !important;
    opacity: 1;
}

.quality-module:not(:nth-child(2n)) {
    border-right: none;
    align-items: right;
}

.module-img-text-wrapper {
    display: flex;
    flex-direction: row;
}

.image-wrapper {
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem
}

.circle-img {
    width: 100px;
    padding: 0.25rem 2rem 0 0;
    max-width: none;
}

.module-header {
    font-family: 'Glacial-Indifference-Bold';
    font-size: 32px;
    color: var(--dark-burgundy);
    text-align: left;
    margin-bottom: 1rem;
}

.module-text {
    font-family: 'Glacial-Indifference-Regular';
    font-size: 16px;
    text-align: left;
}


@media only screen and (max-width: 1300px) {}


@media only screen and (max-width: 900px) {


    .quality-module {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 2rem 0rem;
        width: calc(75%);
        border-right: none;
    }

    .quality-module:not(:nth-child(2n)) {
        border-right: none;
    }
}




@media only screen and (max-width: 700px) {
    .quality-text-3 {
        max-width: 100%;
    }

    .module-img-text-wrapper {
        flex-direction: column;
    }

    .image-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .module-header {
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .circle-img {
        padding: 0;
    }

    .module-text {
        text-align: center;
    }



}

@media only screen and (max-width: 500px) {
    .quality-module {

        width: calc(100%);

    }

}


@media only screen and (max-height: 400px) {}


@media (max-width: 620px) {}