/* Contact */

.bottom-section {
  background: linear-gradient(
    to bottom right,
    var(--dark-burgundy),
    var(--burgundy),
    var(--salmon),
    var(--pink)
  );

  border-radius: 30px 30px 0 0;
  margin-bottom: -30px;
}

.contact-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 100px;
  margin-bottom: 30px;
}

.contact-text-container {
  display: flex;
  flex-direction: column;
  max-width: 50%;
}

#ContactForm {
  margin-top: 25px;
}

.contact-header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  margin: 0 auto;
  margin-top: 60px;
}

.contact-heading-text {
  font-family: "Glacial-Indifference-Regular";
  text-align: center;
  font-size: 20px;
  padding: 0 0 1rem 0;
  line-height: 1.25;
}

.contact-header-container .heading-primary {
  text-align: center;
}

.test {
  /* width: 100%; */
  margin: 0 auto;
  text-align: center;
  font-size: 36px;
  font-family: "Glacial-Indifference-Bold";
}

.contact-content-container {
  display: flex;
  flex-direction: column;
  border-radius: var(--web-border-radius);
  padding: 2rem 3rem;
  margin: 0 40px 20px 40px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 5px;
  max-width: 95%;
  background-color: white;
  font-family: "Glacial-Indifference-Regular";
  opacity: 0;
  width: 30rem;
}

.contact-input-field {
  padding: 1rem 0.5rem 1rem 1rem;
  width: 94%;
  font-size: 1.1rem;
  font-family: "Glacial-Indifference-Regular";
  background: #f0f0f0;
  border-radius: var(--web-border-radius);
  border: none;
  margin-bottom: 2rem;
  margin-top: 5px;
}

.contact-textarea-field {
  padding: 0 0 0 0.1rem;
  font-size: 1.1rem;
  letter-spacing: 1px;
}

.message-field {
  height: 100px;
  /* Adjust the height value as needed */
  resize: vertical;
  margin-bottom: 1rem;
}

.contact-btn-container {
  display: flex;
  align-self: flex-end;
}

.contact-input-header {
  font-size: 1.35rem;
  font-family: "Glacial-Indifference-Regular";
}

.submit-container {
  display: flex;
}

.submit-btn {
  border-radius: var(--web-border-radius);
  border-color: var(--burgundy);
  border-width: 2px;
  font-family: "Glacial-Indifference-Regular";
  color: #ffffff;
  font-size: 1rem;
  background: var(--burgundy);
  padding: 0.75rem 1.25rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  border-style: solid;
  transition: background-color 0.25s ease-in-out;
  margin-right: auto;
}

.submit-btn.sent {
  cursor: not-allowed;
}

.submit-btn:hover {
  cursor: pointer;
  color: black;

  border-color: var(--burgundy);
  border-width: 2px;
  background: white;
  border-style: solid;
}

.contact-text-container.fadeRight {
  animation: fadeRight 1.25s;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
}

.contact-text-container.animation-done {
  animation: none !important;
  opacity: 1;
}

.contact-content-container.fadeUp {
  animation: fadeUp 1.25s;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
}

.contact-content-container.animation-done {
  animation: none !important;
  opacity: 1;
}

@media only screen and (max-width: 1300px) {
  .contact-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
  }

  .contact-text-container {
    max-width: 100%;
    margin-bottom: 60px;
  }

  .contact-content-container {
    max-width: 95%;
    padding: 2rem 2rem;
  }
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 700px) {
}

@media only screen and (max-width: 500px) {
  .contact-text-container {
    max-width: 100%;
  }
}

@media only screen and (max-height: 400px) {
}

@media (max-width: 620px) {
}
