.stats-container {
  background-color: white;
  position: relative;
}

.stats-page {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.stats-header-container {
  text-align: left;
  margin-bottom: 1rem;
  max-width: 70%;
}

.stats-heading {
  font-family: "Glacial-Indifference-Bold";
  font-size: 64px;
  font-weight: bolder;
  text-transform: uppercase;
  margin-bottom: 0;
}

.stats-text-container {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  opacity: 0;
}

.stats-text-container.fadeRight {
  animation: fadeRight 1.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
}

.stats-text-container.animation-done {
  animation: none !important;
  opacity: 1;
}

.stats-text {
  max-width: 70%;
  text-align: left;
  line-height: 1.5;
  color: var(--dark-burgundy);
  font-size: 20px;
  font-family: "Glacial-Indifference-Regular";
  margin-bottom: 20px;
}

.stats-bottom-container {
  display: flex;
  flex-direction: row;
  padding-bottom: 5rem;
}

.stats-image-container {
  position: relative;
  margin-top: -3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 500px;
  max-width: 800px;
  aspect-ratio: 16 / 9;
  background-color: rgb(238, 232, 232);
}

.custom-light-image {
  width: 20rem;
}

.stats-offset-img {
  width: 90%;
}

.stats-num-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 -4rem 0 -4rem;
  background: linear-gradient(
    to right,
    var(--pink),
    var(--salmon),
    var(--burgundy),
    var(--dark-burgundy)
  );
  border-radius: 0 0 30px 30px;
}

.stats-images-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 80%;
  justify-content: space-between;
  margin-bottom: 40px;
  margin-top: 40px;
  align-self: center;
  color: white;
}

.stats-image {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.count-box {
  position: relative;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  font-family: "Glacial-Indifference-Bold";
  align-items: center;
}

.count-box::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 90%;
  height: 6px;
  background: white;
}

.count-number {
  font-size: 110px;
  font-weight: bold;
}

.count-text {
  font-size: 24px;
  font-weight: bold;
  white-space: pre-line;
  text-align: center;
  height: 56px;
  font-family: "Glacial-Indifference-Bold";
}

.project-description-width {
  text-align: center;
}

.paragraph-position {
  color: black;
  font-family: "Glacial-Indifference-Regular";
  font-size: 1.25rem;
  margin: 0;
}

.paragraph-qualifications {
  color: black;
  font-family: "Glacial-Indifference-Regular";
  font-size: 1.2rem;
  font-style: italic;
  margin: 0.75rem;
}
@media only screen and (max-width: 1500px) {
  .stats-text {
    max-width: 80%;
  }
  .stats-image-container {
    margin-top: 0rem;
  }
}
@media only screen and (max-width: 1300px) {
  .stats-header-container {
    text-align: left;
    margin-bottom: 1rem;
    max-width: 85%;
  }

  .count-number {
    font-size: 90px;
  }
  .stats-image-container {
  }
}

@media only screen and (max-width: 900px) {
  .stats-bottom-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 2rem;
    width: 100%;
  }

  .stats-header-container {
    text-align: center;
    max-width: 100%;
  }

  .stats-image-container {
    margin-top: 0px;
    display: flex;
    align-items: center;
  }
  .coming-soon-tag {
    font-size: 1rem;
    top: 0.8rem;
    right: -1.7rem;
  }

  .stats-text-container {
    margin-bottom: 20px;
  }

  .stats-text {
    max-width: 100%;
    text-align: center;
  }

  .count-box::after {
    width: 100px;
  }

  .stats-num-bar {
    margin: 0 -2rem;
  }

  .count-text {
    font-size: 18px;
  }

  .count-number {
    font-size: var(--stats-mobile-font);
  }
}

@media only screen and (max-width: 700px) {
  .stats-heading {
    font-size: var(--header-font-size-m);
  }

  .stats-text {
    font-size: 18px;
    margin-bottom: 0px;
  }

  .stats-images-container {
    margin-bottom: 0px;
  }

  .stats-offset-img {
    width: 100%;
  }

  .stats-image {
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
  }

  .count-box {
    position: relative;
    width: 100%;
    /* margin-bottom: 20px; */
    text-align: center;
  }

  .count-text {
    font-size: 18px;
  }

  .count-number {
    font-size: var(--stats-mobile-font);
  }
  .stats-image-container {
    min-width: 300px;
  }
}

@media (max-width: 600px) {
  .custom-light-image {
    width: 12rem;
  }
}

@media (max-width: 620px) {
}
