/*Profiles*/
.founders-container {
    min-height: 50vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;

    /* background: linear-gradient(
            to top,
            var(--grey),
            white
        );  */
}




.profile-header-container {
    text-align: center;
}

.profile-section-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: 80%; */
}



.profile-section-heading {
    font-size: 40px;
    font-family: 'Glacial-Indifference-Bold';
    color: var(--dark-burgundy);
    margin-top: 1.5em;
}

.profiles-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    flex-wrap: wrap;
    justify-content: center;
    max-width: 90%;
    margin: 0 0 0 0;
    justify-content: space-between;

}

.founders-wrapper {
    grid-template-columns: repeat(3, 1fr);
}



.profile-text-header {
    font-size: 24px;
    font-family: 'Glacial-Indifference-Regular';
    color: var(--dark-burgundy);
    padding-top: 0.75rem;
}

.profile-card {
    position: relative;
    margin: 0 20px 50px;
    max-width: 450px;
}


.profile-img-container {}

.profile-card:hover {
    transform: scale(1.015);
    cursor: pointer;
}

.profile-img-container img {
    width: 300px;
    object-fit: cover;
    transition: transform 0.3s ease;
    border-radius: var(--web-border-radius);
    filter: brightness(0.9);

}

.profile-name-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    text-align: left;
    font-family: 'Glacial-Indifference-Bold';
    font-size: 32px;
    width: 40%;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
}



.profile-popup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;

}

.profile-popup-container.open {
    opacity: 1;
    visibility: visible;

}

.popup {
    max-height: 75vh;
    position: relative;
}


.profile-popup-flex {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    height: 75vh;
    width: 75vw;
    max-width: 1200px;
    max-height: 500px;
    min-width: 250px;
    border-radius: var(--web-border-radius);
    /* Add this line */
}

.profile-popup-img-container {
    background-color: transparent;
}

.profile-popup-img-container img {
    object-fit: cover;
    vertical-align: middle;
    width: 350px;
    height: 500px;
    max-width: none;

}

.profile-popup-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    height: 100%;
    overflow-y: auto;

}

.profile-popup-text-area {
    max-width: 80%;
    font-family: 'Glacial-Indifference-Regular';
    max-height: 90%;
}

.text-padding {
    padding: 10px 0 0 0;
}

.name-heading-popup {
    font-size: 50px;
    font-family: 'Glacial-Indifference-Bold';
}

.description-text {
    font-size: 28px;
    color: var(--dark-burgundy)
}

.education-text {
    color: var(--salmon);
    font-size: 20px;
}

.writeup-text {
    font-size: 16px;
    padding-bottom: 40px;
}

.close-button-container {
    position: absolute;
    top: 20px;
    right: 20px;
}

.close-button {
    width: 30px;
    height: 30px;
    transform: scale(1.5);
    border: none;
    outline: none;
    cursor: pointer;
}

@media only screen and (max-width: 1300px) {
    .profile-img-container img {
        /* width: auto; */
        height: auto;
    }

    .profiles-wrapper {

        display: grid;
        grid-template-columns: repeat(3, 1fr);
        max-width: 100%;
    }
}


@media only screen and (max-width: 900px) {
    .profiles-wrapper {
        max-width: 100%;
        grid-template-columns: repeat(2, 1fr);

    }

    .profile-popup-img-container {
        display: none;
    }

    .text-padding {
        padding: 20px 0 20px 0;
    }

    .description-text {
        padding-top: 20px;
    }


}




@media only screen and (max-width: 700px) {


    .profiles-wrapper {
        grid-template-columns: auto;
        max-width: 100%;
        justify-content: space-evenly;

    }

    .profile-section-wrapper {
        width: 100%;
    }

    .name-heading-popup {
        font-size: 42px;
        font-family: 'Glacial-Indifference-Bold';
    }

    .description-text {
        font-size: 20px;
        color: var(--dark-burgundy)
    }

    .education-text {
        color: var(--salmon);
        font-size: 16px;
    }

    .writeup-text {
        font-size: 14px;
        padding-bottom: 40px;
        line-height: 1.25;
    }
}


@media only screen and (max-width: 500px) {
    .profile-section-heading {
        font-size: 32px;
    }
}

@media only screen and (max-width: 400px) {
    .profile-name-container {
        font-size: 28px;
        padding: 5px;

    }


}

@media (max-width: 620px) {}