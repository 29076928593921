/* Home */
.home-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  padding: 0;
  z-index: 2;
}

.video-hero {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  border-radius: 0 0 30px 30px;
  z-index: -1;
}

.home-text-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 8vw;
}

.home-text-container .text-primary {
  margin-top: 15rem;
  max-width: 60%;
  color: white;
}

.bold {
  color: var(--burgundy);
}

.home-icons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
}

.home-icons-container > * {
  color: white;
  margin: 0 2rem;
}

.home-icons {
  transform: scale(2.5);
}

.home-icons:hover {
  cursor: pointer;
}

.imgs-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  position: relative;
  /* width: 50%; */
}

.img1 {
  width: 600px;
  /* Responsive image width */
  /* max-width: 300px; Set maximum width */
  align-self: flex-start;
  /* Bottom alignment */
  /* Other styles for image 1 */
}

@media (min-width: 800px) {
  .img1 {
    width: 800px;
    /* Responsive image width */
  }

  /* .home-container {
       padding-top: calc(10em + 10vw);
    } */
}

@media (min-width: 1050px) {
  .img1 {
    width: 1000px;
    /* Responsive image width */
  }

  /* .home-container {
       padding-top: calc(1em + 10vw);
    } */
}

@media (min-width: 1500px) {
  .img1 {
    width: 1200px;
    /* Responsive image width */
  }

  /* .home-container {
           padding-top: calc(1em + 5vw);
        } */
}

.img2 {
  width: 100%;
  /* Responsive image width */
  max-width: 300px;
  /* Set maximum width */
  align-self: center;
  /* Vertical center alignment */
}

.logo-container {
  display: flex;
  align-items: center;
}

.crbonlabs-icon-home {
  max-width: 100%;
}

@media only screen and (min-width: 2250px) {
  .home-container {
    padding-top: 40em;
  }

  .home-text-container {
    padding-top: 150px;
  }
}

@media only screen and (max-width: 1300px) {
}

@media only screen and (max-width: 900px) {
  .home-text-container .text-primary {
    max-width: 75%;
  }
}

@media only screen and (max-width: 700px) {
  .home-text-container .text-primary {
    margin-top: 25vw;
    max-width: 90%;
    font-size: 1.5rem;
  }
}

@media only screen and (max-height: 400px) {
}

@media (max-width: 620px) {
}
